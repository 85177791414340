import { type ClassValue, clsx } from "clsx";
import { formatInTimeZone } from "date-fns-tz";
import { twMerge } from "tailwind-merge";

import type { DocumentCategoryDTO, ExtraFeeDTO, MemberDTO, MemberRegistrationDTO } from "@/types/dto";
import type { Updater } from "@tanstack/vue-table";
import { addMinutes, format, isWithinInterval } from "date-fns";
import type { Ref } from "vue";
import { api } from "./api";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function valueUpdater<T extends Updater<any>>(updaterOrValue: T, ref: Ref) {
    ref.value = typeof updaterOrValue === "function" ? updaterOrValue(ref.value) : updaterOrValue;
}

export const googleCalendarAddByUrl = "https://calendar.google.com/calendar/u/0/r/settings/addbyurl";

export const getImageUrlFromApi = (assetId: string, orgId: string) => {
    return api(`/assets/serve/${assetId}?orgId=${orgId}`);
};

export const hours = [
    "00:00",
    "00:15",
    "00:30",
    "00:45",
    "01:00",
    "01:15",
    "01:30",
    "01:45",
    "02:00",
    "02:15",
    "02:30",
    "02:45",
    "03:00",
    "03:15",
    "03:30",
    "03:45",
    "04:00",
    "04:15",
    "04:30",
    "04:45",
    "05:00",
    "05:15",
    "05:30",
    "05:45",
    "06:00",
    "06:15",
    "06:30",
    "06:45",
    "07:00",
    "07:15",
    "07:30",
    "07:45",
    "08:00",
    "08:15",
    "08:30",
    "08:45",
    "09:00",
    "09:15",
    "09:30",
    "09:45",
    "10:00",
    "10:15",
    "10:30",
    "10:45",
    "11:00",
    "11:15",
    "11:30",
    "11:45",
    "12:00",
    "12:15",
    "12:30",
    "12:45",
    "13:00",
    "13:15",
    "13:30",
    "13:45",
    "14:00",
    "14:15",
    "14:30",
    "14:45",
    "15:00",
    "15:15",
    "15:30",
    "15:45",
    "16:00",
    "16:15",
    "16:30",
    "16:45",
    "17:00",
    "17:15",
    "17:30",
    "17:45",
    "18:00",
    "18:15",
    "18:30",
    "18:45",
    "19:00",
    "19:15",
    "19:30",
    "19:45",
    "20:00",
    "20:15",
    "20:30",
    "20:45",
    "21:00",
    "21:15",
    "21:30",
    "21:45",
    "22:00",
    "22:15",
    "22:30",
    "22:45",
    "23:00",
    "23:15",
    "23:30",
    "23:45",
];

export const alpha3Countries = [
    { code: "BEL", name: "Belgique", indicative: "+32" },
    { code: "FRA", name: "France", indicative: "+33" },
];

export function getCountryNameByCode(code: string): string {
    const country = alpha3Countries.find(c => c.code === code);
    return country ? country.name : "";
}

export function validateCountryCode(code: string): boolean {
    return alpha3Countries.some(c => c.code === code);
}

export const alpha2Countries = [
    { code: "BE", name: "Belgique", indicative: "+32" },
    { code: "FR", name: "France", indicative: "+33" },
];

export function getFillingRate(
    maxAttendees: number,
    reservedSeats: number,
    confirmedSeats: number,
    cancelledSeats: number,
) {
    const rate = reservedSeats + confirmedSeats + cancelledSeats;
    return isNaN(rate) ? 0 : rate;
}

export function getAvailableHoursByStartTimeAndDuration(startTime: string, duration: number) {
    const startDateTime = new Date(startTime);
    const endDateTime = addMinutes(startDateTime, duration);
    const availableHours = hours.filter(hour => isWithinInterval(startDateTime, { start: hour, end: endDateTime }));
    return availableHours;
}

export const pricingPlans = [
    { value: "none", label: "Aucun" },
    { value: "unlimited", label: "Illimité" },
    { value: "free-trial", label: "Gratuit" },
    { value: "default-monthly", label: "Défaut (mensuel)" },
    { value: "test", label: "Test" },
];

export const pricingPlanTranslator = (plan: string) => {
    return pricingPlans.find(p => p.value === plan)?.label || "-";
};

export const roles = [
    { label: "Utilisateur basique", value: "user" },
    { label: "Gérant d'établissement", value: "est-manager" },
    { label: "Gérant d'organisation", value: "org-manager" },
    { label: "Support", value: "support" },
    { label: "Administrateur", value: "admin" },
];

export function roleTranslator(role: string) {
    return roles.find(r => r.value === role)?.label || "-";
}

export function genderTranslator(gender: string) {
    switch (gender) {
        case "M":
            return "Homme";
        case "F":
            return "Femme";
        case "O":
            return "Autres";
        default:
            return "-";
    }
}

export function prestationStatusTranslator(status: string) {
    switch (status) {
        case "confirmed":
            return "Confirmée";
        case "postponed":
            return "Reportée";
        case "cancelled":
            return "Annulée";
        default:
            return "Inconnu";
    }
}

export function orderStatusTranslator(status: string) {
    switch (status) {
        case "pending":
            return "En attente";
        case "completed":
            return "Confirmée";
        case "cancelled":
            return "Annulée";
        case "require-action":
            return "Action requise";
        default:
            return "Inconnu";
    }
}

export function invoiceStatusTranslator(status: string) {
    switch (status) {
        case "pending":
            return "En attente de paiement";
        case "paid":
            return "Payée";
        case "cancelled":
            return "Annulée";
        case "overdue":
            return "En retard de paiement";
        case "processing":
            return "En traitement";
        default:
            return "Inconnu";
    }
}

export function ageStrategyTranslator(strategy: string) {
    switch (strategy) {
        case "years":
            return "ans";
        case "months":
            return "mois";
        default:
            return "-";
    }
}

export function registryTypeTranslator(type: string) {
    switch (type) {
        case "user-created":
            return "Utilisateur créé";
        case "user-login":
            return "Utilisateur connecté";
        case "member-created":
            return "Membre créé";
        case "registration-created":
            return "Inscription créée";
        case "registration-confirmed":
            return "Inscription confirmée";
        case "registration-cancelled":
            return "Inscription annulée";
        case "registration-attendee-absence":
            return "Absence d'un participant";
        case "prestation-cancelled":
            return "Prestation annulée";
        case "prestation-holder-absence":
            return "Absence d'un membre";
        case "recovery-token-created":
            return "Jeton de récupération créé";
        case "credit-note-emitted":
            return "Note de crédit émise";
        default:
            return "-";
    }
}

export function serviceVisibilityTranslator(visibility: string) {
    switch (visibility) {
        case "hidden":
            return "Brouillon (Vous seul)";
        case "private":
            return "Privé (Seuls les participants inscrits)";
        case "protected":
            return "Protégé (Tous les membres de l'établissement)";
        case "public":
            return "Public (Tout le monde, apparaît sur le site web)";
        case "archived":
            return "Archivé (Aucun accès)";
        default:
            return "-";
    }
}

export function registrationStatusTranslator(status: string) {
    switch (status) {
        case "invited":
            return "Invité";
        case "invitation-expired":
            return "Invitation expirée";
        case "invitation-declined":
            return "Invitation déclinée";
        case "reserved":
            return "En attente de paiement";
        case "confirmed":
            return "Confirmée";
        case "cancelled":
            return "Annulée";
        case "absent":
            return "Absent";
        default:
            return "-";
    }
}

export function documentCategoryTranslator(category: DocumentCategoryDTO) {
    switch (category) {
        case "legal.roi":
            return "Règlement d’ordre intérieur";
        case "legal.mentions":
            return "Mentions légales";
        case "legal.other":
            return "Autre";
        case "billing.invoice":
            return "Facture";
        case "billing.order":
            return "Commande";
        case "certificate.health-mutual":
            return "Attestation mutuelle";
        case "certificate.tax":
            return "Attestation fiscale";
        case "report.orders":
            return "Commandes";
        case "report.planning-activities":
            return "Planning";
        case "report.members-list":
            return "Liste de membres";
        default:
            return "-";
    }
}

export function includeInvitedRegistration(registration: MemberRegistrationDTO) {
    return registration.prestationsAndRegistrations.some(pr => pr.registration.status === "invited");
}

export function formatDateTime(date: string) {
    return format(date, "dd/MM/yyyy HH:mm");
}

export function formatDateTimeLong(date: string) {
    return format(date, "PPPp");
}

export function extractTimeFromDate(date: string) {
    return format(date, "HH:mm");
}

export function formatDate(date: string) {
    return format(date, "dd/MM/yyyy");
}

export function formatDateTimeInTimezone(date: string, timezone: string) {
    return formatInTimeZone(date, timezone, "dd/MM/yyyy HH:mm:ss");
}

export function formatDisplayTaxes(taxes: number) {
    return taxes / 100 + "%";
}

export function formatDisplayDiscount(discount: number): number {
    return discount / 100;
}

export function formatSendableDiscount(discount: number): number {
    return Math.round(discount * 100);
}

export function formatDisplayPrice(price: number): string {
    return (price / 100).toFixed(2) + "€";
}

export function formatSendablePrice(price: number): number {
    return Math.round(price * 100);
}

export function formatDuration(minutes: number): string {
    if (minutes === null || minutes === undefined) return "-";
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    const hTxt = String(h);
    const mTxt = String(m).padStart(2, "0");
    return h > 0 ? `${hTxt} h ${mTxt}` : `${mTxt} min`;
}

export function randomColor(options?: { pastel?: boolean }): string {
    const min = options?.pastel ? 127 : 64;
    const max = options?.pastel ? 253 : 192;

    const R = Math.floor(Math.random() * (max - min) + min);
    const G = Math.floor(Math.random() * (max - min) + min);
    const B = Math.floor(Math.random() * (max - min) + min);

    const rgb = (R << 16) + (G << 8) + B;
    return `#${rgb.toString(16).padStart(6, "0")}`;
}

export function hexToRgb(hex: string) {
    // Remove # if present
    hex = hex.replace(/^#/, "");

    // Parse hex values
    const bigint = parseInt(hex, 16);

    return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
    };
}

export const getTotalPrice = (price: number, extraFees?: ExtraFeeDTO[]) => {
    if (!extraFees || extraFees.length === 0) return price;

    const totalExtraFees = extraFees.reduce((sum, fee) => sum + fee.price, 0);
    return price + totalExtraFees;
};

export function fullNameBuilder(member: MemberDTO | undefined | null, defaultValue?: string): string {
    if (member?.firstName && member?.lastName) {
        return `${member?.firstName} ${member?.lastName}`;
    } else if (member?.firstName) {
        return member?.firstName;
    } else if (member?.lastName) {
        return member?.lastName;
    } else if (member?.email) {
        return member?.email;
    } else if (defaultValue) {
        return defaultValue;
    } else {
        return "Membre";
    }
}

export function getDisplayRate(maxSeats: number, confirmedSeats: number, reservedSeats: number) {
    if (maxSeats === -1) return "Infini";

    const takenSeats = confirmedSeats + reservedSeats;

    return `${takenSeats}/${maxSeats}`;
}
