<script setup lang="ts">
import { ChevronDown, ChevronUp } from "lucide-vue-next";
import { onBeforeUnmount, onMounted, ref } from "vue";

// Props
defineProps<{
    className?: string;
}>();

// Refs
const containerRef = ref<HTMLElement | null>(null);
const showTopIndicator = ref(false);
const showBottomIndicator = ref(false);

// Méthodes
const checkScroll = () => {
    if (!containerRef.value) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.value;
    showTopIndicator.value = scrollTop > 20;
    showBottomIndicator.value = scrollHeight - scrollTop - clientHeight > 20;
};

const scrollToPosition = (direction: "top" | "bottom") => {
    if (!containerRef.value) return;

    const container = containerRef.value;
    const scrollOptions = {
        behavior: "smooth" as const,
        top: direction === "top" ? 0 : container.scrollHeight,
    };

    container.scrollTo(scrollOptions);
};

// Gestion du cycle de vie et des observers
onMounted(() => {
    const container = containerRef.value;
    if (container) {
        // Initial check
        checkScroll();

        // Event listener pour le scroll
        container.addEventListener("scroll", checkScroll);

        // Observer pour les changements de contenu
        const observer = new MutationObserver(checkScroll);
        observer.observe(container, {
            childList: true,
            subtree: true,
            characterData: true,
            attributes: true,
        });

        // Cleanup
        onBeforeUnmount(() => {
            container.removeEventListener("scroll", checkScroll);
            observer.disconnect();
        });
    }
});
</script>

<template>
    <div class="relative flex grow overflow-y-auto">
        <Transition name="fade">
            <button
                v-show="showTopIndicator"
                @click="scrollToPosition('top')"
                class="absolute left-1/2 top-0 z-10 -translate-x-1/2 transform rounded-b-md bg-zinc-800/80 p-1 text-white transition-all hover:bg-zinc-800"
                aria-label="Défiler vers le haut">
                <ChevronUp class="h-5 w-5" />
            </button>
        </Transition>

        <div
            ref="containerRef"
            :class="['relative flex grow flex-col overflow-y-auto', className]"
            @scroll="checkScroll">
            <slot />
        </div>

        <Transition name="fade">
            <button
                v-show="showBottomIndicator"
                @click="scrollToPosition('bottom')"
                class="absolute bottom-0 left-1/2 z-10 -translate-x-1/2 transform rounded-t-md bg-zinc-800/80 p-1 text-white transition-all hover:bg-zinc-800"
                aria-label="Défiler vers le bas">
                <ChevronDown class="h-5 w-5" />
            </button>
        </Transition>
    </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
